:root {
  --gray: #8b8b8b;
  --bgColor: #2a2a36;
  --padding: 1rem;
}

html,
body {
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.1rem;
  margin: 0;
  height: 100%;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
p,
a {
  margin: 0;
}

.main-container {
  height: 100vh;
  width: 100vw;
  background: var(--bgColor);
  padding: var(--padding);
}

.padding-sides {
  padding: 0 var(--padding);
}